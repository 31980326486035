<template>
  <Dialog ref="dialog" :title="$t('title.agency.userSearch')" :color="'next'">
    <v-container class="pt-0 px-8">
      <v-form lazy-validation ref="form" @submit.prevent>
        <v-row>
          <v-col>
            <v-chip
              ripple
              class="font-weight-black float-right"
              @click="onClickClearButton()"
              >{{ $t('button.clear') }}<v-icon>mdi-delete</v-icon></v-chip
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.loginId"
              :label="$t('header.userMasterTable.loginId')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.userName"
              :label="$t('header.userMasterTable.userName')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.salesCode"
              :label="$t('header.userMasterTable.salesCode')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.agencyCode"
              :label="$t('header.userMasterTable.agencyCode')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.agencyName"
              :label="$t('header.userMasterTable.agencyName')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.chargePersonName"
              :label="$t('header.userMasterTable.chargePersonName')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.recruiterId"
              :label="$t('header.userMasterTable.recruiterId')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col :class="conditionStyle">
            <v-select
              v-model="condition.role"
              :items="roleList"
              :label="$t('header.userMasterTable.role')"
              multiple
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="canShow('isDeleted')">
          <v-col :class="conditionStyle">
            <v-select
              v-model="condition.isDeleted"
              :items="isDeletedList"
              :label="$t('header.userMasterTable.isDeleted')"
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <template v-slot:footer>
      <v-btn width="33%" class="back" dark @click="close()">{{
        $t('button.cancel')
      }}</v-btn>
      <v-btn width="33%" class="next" dark @click="onClickSearchButton()">
        {{ $t('button.search') }}
      </v-btn>
    </template>
    <ErrorDialog ref="errorDialog"></ErrorDialog>
  </Dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import Dialog from '@/components/organisms/agency/Dialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import { UserDeleteStatus } from '@/lib/const';
import { Role } from '@/lib/role';

export default {
  components: { Dialog, ErrorDialog },
  data() {
    return {
      // ユーザー検索項目
      condition: {
        userName: '',
        loginId: '',
        salesCode: '',
        agencyCode: '',
        agencyName: '',
        chargePersonName: '',
        recruiterId: '',
        role: '',
        isDeleted: '',
      },

      // 権限の選択肢
      roleList: [],

      // 廃止フラグの選択肢
      isDeletedList: [
        {
          text: this.$t('label.isDeleted.notDeleted'),
          value: UserDeleteStatus.NotDeleted,
        },
        {
          text: this.$t('label.isDeleted.deleted'),
          value: UserDeleteStatus.Deleted,
        },
      ],

      // 編集フォームのスタイル指定
      conditionStyle: ['my-0', 'py-0'],
    };
  },
  methods: {
    ...mapGetters('user', ['userDetail']),

    async open() {
      this.$refs.dialog.open();
      this.setRoleChoices();
    },
    close() {
      this.$refs.dialog.close();
    },
    async init() {
      this.condition.userName = '';
      this.condition.loginId = '';
      this.condition.salesCode = '';
      this.condition.agencyCode = '';
      this.condition.agencyName = '';
      this.condition.chargePersonName = '';
      this.condition.recruiterId = '';
      this.condition.role = '';
      this.condition.isDeleted = '';
    },

    // 表示するかどうか
    canShow(key) {
      return Role.canShow(this.userDetail().role, key);
    },

    // 権限の選択肢をセットする
    setRoleChoices() {
      // 操作可能な権限
      const roleList = Role.getChoices(this.userDetail().role);
      // 権限の選択肢表示の更新
      this.$set(this, 'roleList', roleList);
    },

    // 検索ボタンの押下時
    onClickSearchButton() {
      // バリデーションエラーが発生した場合、以降の処理を中止する
      if (!this.$refs.form.validate()) return;

      // 検索項目
      const condition = {
        ...(this.condition.userName && {
          userName: this.condition.userName,
        }),
        ...(this.condition.loginId && {
          loginId: this.condition.loginId,
        }),
        ...(this.condition.salesCode && {
          salesCode: this.condition.salesCode,
        }),
        ...(this.condition.agencyCode && {
          agencyCode: this.condition.agencyCode,
        }),
        ...(this.condition.agencyName && {
          agencyName: this.condition.agencyName,
        }),
        ...(this.condition.chargePersonName && {
          chargePersonName: this.condition.chargePersonName,
        }),
        ...(this.condition.recruiterId && {
          recruiterId: this.condition.recruiterId,
        }),
        ...(this.condition.role && {
          role: this.condition.role,
        }),
        ...(this.condition.isDeleted && {
          isDeleted: this.condition.isDeleted,
        }),
      };
      this.$emit('onSuccess', condition);
    },

    // クリアボタンの押下時
    onClickClearButton() {
      this.init();
      this.$emit('onClear');
    },

    // 検索エラー時
    onErrorSearch() {
      this.$refs.errorDialog.open(
        this.$t('title.agency.userSearchError'),
        this.$t('error.userListNotFound')
      );
    },
  },
};
</script>
