<template>
  <Dialog
    ref="dialog"
    :title="$t('title.agency.userImportList')"
    width="700px"
    :color="'next'"
  >
    <v-container>
      <v-data-table
        calculate-widths
        class="elevation-1"
        :headers="headers"
        :items="records"
        :loading-text="$t('description.userImportReading')"
        :no-data-text="$t('error.userImportNotFound')"
        :options.sync="options"
        :footer-props="{
          itemsPerPageText: '1ページあたりの行数',
          itemsPerPageOptions: itemsPerPageOptions,
        }"
      >
        <template v-slot:[`item.detail`]="{ item }" class="pa-0">
          <v-menu offset-y left>
            <template v-slot:activator="{ on }">
              <v-btn color="red" fab x-small dark v-on="on">
                <v-icon color="white">edit</v-icon>
              </v-btn>
            </template>
            <v-list class="ma-0 pa-0">
              <v-list-item @click="onClickDelete(item)">
                <v-icon>delete</v-icon>
                <v-list-item-action class="mx-0" dark color="next">{{
                  $t('button.delete')
                }}</v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-container>

    <template v-slot:footer>
      <v-btn
        width="33%"
        style="font-size:16px"
        class="back"
        dark
        @click="close()"
      >
        {{ $t('button.close') }}
      </v-btn>
    </template>
    <DeleteDialog
      :title="$t('title.agency.userImportDelete')"
      @onSuccess="onSuccessDeleteUserImport"
      ref="userImportDeleteDialog"
    />
    <CompletedDialog ref="completedDialog"></CompletedDialog>
    <ErrorDialog ref="errorDialog"></ErrorDialog>
  </Dialog>
</template>

<script>
import moment from 'moment';
import Dialog from '@/components/organisms/agency/Dialog';
import DeleteDialog from '@/components/organisms/agency/DeleteDialog';
import CompletedDialog from '@/components/organisms/agency/CompletedDialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import { Role } from '@/lib/role';
import { getUserImports, deleteUserImport } from '@/apis/agency/userImports';
import { mapGetters, mapActions } from 'vuex';
import { splitMessageLine } from '@/lib/commonUtil';
import {
  UserImportListHeaders,
  UserImportListDisplayDefault,
} from '@/lib/const';

export default {
  components: {
    Dialog,
    ErrorDialog,
    DeleteDialog,
    CompletedDialog,
  },
  data() {
    return {
      headers: UserImportListHeaders,
      records: [],

      // ユーザーインポート一覧取得オプション
      options: {
        page: UserImportListDisplayDefault.page,
        itemsPerPage: UserImportListDisplayDefault.itemsPerPage,
        sortBy: [],
        sortDesc: [],
      },

      // 1ページあたりの表示件数の選択肢
      itemsPerPageOptions: UserImportListDisplayDefault.itemsPerPageOptions,

      // ユーザーインポート一覧取得件数
      total: 0,

      // 削除ID
      deleteId: null,

      // ローディング中であるかどうか
      isLoading: true,
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchUserImports();
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    ...mapGetters('user', ['userDetail']),
    async open() {
      await this.fetchUserImports();
      this.$refs.dialog.open();
    },
    close() {
      this.$refs.dialog.close();
    },

    // オプションの取得
    getOption() {
      return {
        perPage: this.options.itemsPerPage,
        pageNumber: this.options.page,
        ...(this.options.sortBy.length > 0 && {
          sortKey: this.sortKey[this.options.sortBy[0]],
        }),
        ...(this.options.sortDesc.length > 0 && {
          isDesc: this.options.sortDesc[0],
        }),
      };
    },

    // ユーザーインポート一覧の取得
    async fetchUserImports() {
      const userImports = await getUserImports(this.getOption()).catch(() => {
        // 空データをセットする
        this.$set(this, 'records', [{}]);
      });

      this.$set(this, 'isLoading', false);

      // ユーザーインポート一覧が取得できなかった場合、以降の処理を中止する
      if (!userImports) return;

      // 表示値のマッピング
      const records = userImports.rows.map(userImport => ({
        id: userImport.id,
        userName: userImport.user ? userImport.user.userName : '',
        officeType: Role.getOfficeName(userImport.officeType),
        importDate: moment(userImport.importDate).format('YYYY/MM/DD HH:mm'),
      }));

      // ユーザーインポー一覧表示の更新
      this.$set(this, 'records', records);
      this.$set(this, 'total', userImports.count);
    },

    // 削除ボタンの押下時
    onClickDelete(item) {
      this.$set(this, 'deleteId', item.id);
      this.$refs.userImportDeleteDialog.open();
    },

    // ユーザーインポート削除の成功時
    async onSuccessDeleteUserImport() {
      const result = await deleteUserImport(this.deleteId).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.userDeleteError'),
          this.$t('error.userDeleteBadRequest')
        );
      });
      if (!result) return;

      this.$refs.userImportDeleteDialog.close();
      this.$refs.completedDialog.open(
        this.$t('title.agency.deleteResult'),
        this.$t('success.deleted')
      );

      await this.fetchUserImports();
    },
  },
};
</script>
