import axios from '@/apis/axios';
import { getAccessToken, convertCamelToSnake } from '@/lib/api';

/**
 * ユーザーインポート一覧取得
 */
export const getUserImports = async option => {
  const accessToken = await getAccessToken();
  let params = {};
  if (option) {
    params = Object.assign(params, convertCamelToSnake(option));
  }

  const response = await axios
    .get(`/agencies/user_imports`, {
      params: { ...params },
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 仮ユーザ本登録
 */
export const userDefinitiveRegistration = async (userImportId = null) => {
  const accessToken = await getAccessToken();

  const response = await axios
    .post(`/agencies/user_imports/`,
      { userImportId: userImportId },
      { headers: { 'access-token': accessToken } }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 仮ユーザ削除
 */
export const deleteUserImport = async userImportId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .delete(`/agencies/user_imports/${userImportId}`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};
