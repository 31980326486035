import {
  OfficeType,
  UserBulkImportType,
  UserConfirmTableHeader,
} from '@/lib/const';

export class Master {
  // ユーザー一括インポート種別の取得
  static getUserBulkImportType(isRegister, officeType) {
    if (isRegister && officeType === OfficeType.Sales) {
      return UserBulkImportType.CreateSales;
    } else if (isRegister && officeType === OfficeType.Agency) {
      return UserBulkImportType.CreateAgency;
    } else if (!isRegister && officeType === OfficeType.Sales) {
      return UserBulkImportType.UpdateSales;
    } else {
      return UserBulkImportType.UpdateAgency;
    }
  }

  // ユーザー一括インポートカラムの取得
  static getUserBulkImportColumns(isRegister, officeType) {
    const importType = this.getUserBulkImportType(isRegister, officeType);
    return UserConfirmTableHeader.filter(
      column =>
        column.importType === '*' || column.importType.includes(importType)
    );
  }
}
