import moment from 'moment';
import { UserDeleteStatusDisplay } from '@/lib/const';
import i18n from '@/plugins/i18n';

// 最終更新日の変換
export const convertLastUpdatedDate = date => {
  return date ? moment(date).format('YYYY/MM/DD HH:mm') : null;
};

// 最終ログイン日時の変換
export const convertLastLoginDate = date => {
  return date
    ? moment(date).format('YYYY/MM/DD HH:mm')
    : i18n.t('label.notLogined');
};

// 初回登録日時の変換
export const convertFirstRegisteredDate = date => {
  return date ? moment(date).format('YYYY/MM/DD HH:mm') : null;
};

// 廃止フラグの変換
export const convertIsDeleted = isDeleted => {
  return UserDeleteStatusDisplay[isDeleted];
};
