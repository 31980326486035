<template>
  <Dialog ref="dialog" :title="title" :color="'next'">
    <v-container>
      <p class="text-center">
        {{ $t('description.deleteDialog.confirm') }}
      </p>
    </v-container>

    <template v-slot:footer>
      <v-btn width="33%" class="back" dark @click="close()">{{
        $t('button.cancel')
      }}</v-btn>
      <v-btn
        width="33%"
        class="next"
        :dark="isDeleteButtonEnabled"
        :disabled="!isDeleteButtonEnabled"
        @click="onClickDeleteButton()"
      >
        {{ $t('button.delete') }}
      </v-btn>
    </template>
    <ErrorDialog ref="errorDialog"></ErrorDialog>
  </Dialog>
</template>

<script>
import Dialog from '@/components/organisms/agency/Dialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';

export default {
  components: { Dialog, ErrorDialog },
  props: {
    title: String,
  },
  data() {
    return {
      // 削除ボタンが有効であるかどうか
      isDeleteButtonEnabled: true,
    };
  },
  methods: {
    open() {
      this.$refs.dialog.open();
      this.init();
    },
    close() {
      this.$refs.dialog.close();
    },
    init() {
      this.$set(this, 'isDeleteButtonEnabled', true);
    },
    onClickDeleteButton() {
      this.$set(this, 'isDeleteButtonEnabled', false);

      this.$emit('onSuccess');
    },
  },
};
</script>
