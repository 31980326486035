<template>
  <v-container fluid class="pl-12 pr-12">
    <v-layout column wrap>
      <v-row>
        <v-col>
          <p class="font-weight-black pl-3" style="font-size:32px">
            {{ $t('title.agency.userList') }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <p class="pl-5" style="word-wrap: break-all; max-width:90vw">
          {{ $t('description.userList.explanation1') }}
        </p>
      </v-row>
      <v-row>
        <v-col>
          <v-card outlined height="auto">
            <v-container>
              <v-row>
                <v-col class="py-0" style="max-width:315px">
                  <v-btn
                    class="font-weight-black mx-auto"
                    align="center"
                    width="100%"
                    height="96px"
                    max-width="300px"
                    color="next"
                    style="font-size:20px"
                    :dark="isUserRegisterButtonEnabled"
                    :disabled="!isUserRegisterButtonEnabled"
                    @click="onClickUserRegisterButton()"
                    >{{ $t('button.userRegister') }}</v-btn
                  >
                </v-col>
                <v-col class="py-0">
                  <p class="pl-3 mb-0">
                    {{ $t('description.userList.userRegister') }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined height="auto">
            <v-container>
              <v-row>
                <v-col class="py-0" style="max-width:315px">
                  <v-btn
                    class="font-weight-black"
                    align="center"
                    width="100%"
                    height="96px"
                    max-width="300px"
                    color="next"
                    style="font-size:20px"
                    dark
                    slot="activator"
                    @click="onClickUserSearchButton()"
                    >{{ $t('button.userSearch') }}</v-btn
                  >
                </v-col>
                <v-col class="py-0">
                  <p class="pl-3 mb-0">
                    {{ $t('description.userList.userSearch') }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card outlined height="auto">
            <v-container>
              <v-row>
                <v-col class="py-0" style="max-width:315px">
                  <v-btn
                    class="font-weight-black mx-auto"
                    align="center"
                    width="100%"
                    height="96px"
                    max-width="300px"
                    color="next"
                    style="font-size:20px"
                    :dark="isUserBulkButtonEnabled"
                    :disabled="!isUserBulkButtonEnabled"
                    @click="onClickUserBulkRegisterButton()"
                    >{{ $t('button.userBulkRegister') }}
                  </v-btn>
                </v-col>
                <v-col class="py-0">
                  <p class="pl-3 mb-0">
                    {{ $t('description.userList.userBulkRegister') }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined height="auto">
            <v-container>
              <v-row>
                <v-col class="py-0" style="max-width:315px">
                  <v-btn
                    class="font-weight-black mx-auto"
                    align="center"
                    width="100%"
                    height="96px"
                    max-width="300px"
                    color="next"
                    style="font-size:20px"
                    :dark="isUserBulkButtonEnabled"
                    :disabled="!isUserBulkButtonEnabled"
                    @click="onClickUserBulkUpdateButton()"
                    >{{ $t('button.userBulkUpdate') }}
                  </v-btn>
                </v-col>
                <v-col class="py-0">
                  <p class="pl-3 mb-0">
                    {{ $t('description.userList.userBulkUpdate') }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card outlined height="auto">
            <v-container>
              <v-row>
                <v-col class="py-0" style="max-width:315px">
                  <v-btn
                    class="font-weight-black mx-auto"
                    align="center"
                    width="100%"
                    height="96px"
                    max-width="300px"
                    color="next"
                    style="font-size:20px"
                    :dark="isUserBulkButtonEnabled"
                    :disabled="!isUserBulkButtonEnabled"
                    @click="onClickUserBulkDeleteButton()"
                    >{{ $t('button.userBulkDelete') }}
                  </v-btn>
                </v-col>
                <v-col class="py-0">
                  <p class="pl-3 mb-0">
                    {{ $t('description.userList.userBulkDelete') }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined height="auto">
            <v-container>
              <v-row>
                <v-col class="py-0" style="max-width:315px">
                  <v-btn
                    class="font-weight-black mx-auto"
                    align="center"
                    width="100%"
                    height="96px"
                    max-width="300px"
                    color="next"
                    style="font-size:20px"
                    :dark="isUserBulkButtonEnabled"
                    :disabled="!isUserBulkButtonEnabled"
                    @click="onClickCsvDownloadButton()"
                    >{{ $t('button.userBulkExport') }}
                  </v-btn>
                </v-col>
                <v-col class="py-0">
                  <p class="pl-3 mb-0">
                    {{ $t('description.userList.userBulkExport') }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="pt-y mb-0" style="max-height:75px">
        <v-col class="mb-0 pb-0">
          <p class="font-weight-black px-4 pt-4 mb-1" style="font-size:24px">
            {{ $t('label.userList') }}
          </p>
        </v-col>
        <v-col class="mb-0 pb-0">
          <v-layout class="float-right">
            <v-chip
              ripple
              class="font-weight-black ml-4"
              style="margin-top:15px;"
              :disabled="!isClearSearchConditionButtonEnabled"
              @click="onClickClearButton()"
              >{{ $t('button.conditionClear')
              }}<v-icon>mdi-delete</v-icon></v-chip
            >
          </v-layout>
        </v-col>
      </v-row>
      <v-row style="width:100%" class="ma-0 pa-0">
        <v-col class="ma-0 pa-0">
          <v-data-table
            calculate-widths
            class="elevation-1 ma-0 pa-0"
            :headers="headers"
            :items="records"
            :options.sync="options"
            :server-items-length="total"
            :loading="isLoading"
            :loading-text="$t('description.userReading')"
            :no-data-text="$t('error.userListNotFound')"
            :footer-props="{
              itemsPerPageText: '1ページあたりの行数',
              itemsPerPageOptions: itemsPerPageOptions,
            }"
          >
            <template v-slot:item.detail="{ item }" class="pa-0">
              <router-link
                :to="{
                  name: 'UserDetail',
                  params: { user_id: item.id },
                }"
                :target="isDetailButtonEnabled(item) ? '_blank' : ''"
                :event="isDetailButtonEnabled(item) ? 'click' : ''"
                class="router-link-transparent"
                style="text-decoration:none"
              >
                <template>
                  <v-chip
                    class="font-weight-black float-center"
                    color="next"
                    :dark="isDetailButtonEnabled(item)"
                    :disabled="!isDetailButtonEnabled(item)"
                    ripple
                    link
                    >{{ $t('header.userListTable.detail') }}</v-chip
                  >
                </template>
              </router-link>
            </template>
            <template v-slot:item.comment="{ item }" class="pa-0">
              <p
                v-for="(message, index) in splitMessageLine(item.comment)"
                :key="index"
                class="ma-0 pa-0"
                style="font-size:14px"
                align="left"
              >
                {{ message }}
              </p>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <UserSearchDialog
        @onSuccess="onSuccessSearchUser"
        @onClear="onClearUserSearchCondition"
        ref="userSearchDialog"
      ></UserSearchDialog>
      <!-- ユーザー登録ダイアログ -->
      <FormsDialog
        :showDialog="showCreateUserDialog"
        :title="$t('title.agency.userRegister')"
        :text="null"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.register')"
        :onClickNegativeButton="cancelCreateUser"
        :onClickPositiveButton="createUser"
        :targets="createUserTargets"
      />
      <CompletedDialog ref="completedDialog"></CompletedDialog>
      <ErrorDialog ref="errorDialog"></ErrorDialog>
      <UserCsvFileSelectDialog
        @onSuccess="onSuccessCsvImport"
        ref="csvFileSelectDialog"
        :officeType="officeType"
        :officeCodeList="officeCodeList"
        :loginIdList="loginIdList"
        :isRegister="isRegister"
      />
      <UserImportListDialog ref="userImportListDialog" />
      <UserValidateErrorDialog ref="userValidateErrorDialog" />
      <BulkRegisterListDialog
        ref="bulkRegisterListDialog"
        @onSuccess="onBulkRegisterUser"
      />
      <BulkUpdateListDialog
        ref="bulkUpdateListDialog"
        @onSuccess="onBulkUpdateUser"
      />
      <!-- 登録先選択ダイアログ -->
      <FormsDialog
        :maxWidth="400"
        :showDialog="showSelectOfficeTypeFormsDialog"
        :title="$t('title.agency.selectOfficeType')"
        :text="null"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.next')"
        :onClickNegativeButton="cancelSelectOfficeType"
        :onClickPositiveButton="onCsvImport"
        :targets="selectOfficeTypeDefaultTargets"
      />
    </v-layout>
  </v-container>
</template>
<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import {
  convertLastUpdatedDate,
  convertLastLoginDate,
  convertIsDeleted,
} from '@/lib/agency/util';
import {
  ErrorCode,
  UserListTableHeader,
  UserConfirmTableHeader,
  userListDisplayDefault,
  UserBulkRegisterCsv,
  UserBulkExportCsv,
  UserBulkImportType,
  MaxLength,
  MaxCsvExportRowCount,
  OfficeType,
  RoleType,
} from '@/lib/const';
import { Master } from '@/lib/master';
import { Role } from '@/lib/role';
import { downloadCsv, getCsvFileName } from '@/lib/csv';
import { splitMessageLine } from '@/lib/commonUtil';
import CompletedDialog from '@/components/organisms/agency/CompletedDialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import FormsDialog from '@/components/organisms/agency/FormsDialog';
import UserCsvFileSelectDialog from '@/components/organisms/agency/UserCsvFileSelectDialog';
import UserImportListDialog from '@/components/organisms/agency/UserImportListDialog';
import UserValidateErrorDialog from '@/components/organisms/agency/UserValidateErrorDialog';
import UserSearchDialog from '@/components/organisms/agency/UserSearchDialog';
import BulkRegisterListDialog from '@/components/organisms/agency/BulkRegisterListDialog';
import BulkUpdateListDialog from '@/components/organisms/agency/BulkUpdateListDialog';
import { postUsers, getUsers, postUser, putUsers } from '@/apis/agency/users';
import { getSalesCodeList } from '@/apis/agency/sales';
import { getAgencyCodeList } from '@/apis/agency/agencies';
import { userDefinitiveRegistration } from '@/apis/agency/userImports';

export default {
  name: 'UserList',
  components: {
    CompletedDialog,
    ErrorDialog,
    FormsDialog,
    UserSearchDialog,
    UserCsvFileSelectDialog,
    UserImportListDialog,
    UserValidateErrorDialog,
    BulkRegisterListDialog,
    BulkUpdateListDialog,
  },

  data: vm => ({
    records: [],

    // ユーザー一覧取得オプション
    options: {
      page: userListDisplayDefault.page,
      itemsPerPage: userListDisplayDefault.itemsPerPage,
      sortBy: [],
      sortDesc: [],
    },

    // ユーザー検索条件
    userSearchCondition: {},

    // 1ページあたりの表示件数の選択肢
    itemsPerPageOptions: userListDisplayDefault.itemsPerPageOptions,

    // ユーザー一覧取得件数
    total: 0,

    // 権限の選択肢
    roleList: [],

    // 事業所の選択肢
    officeIdList: [],

    // ユーザー登録データ (デフォルト)
    createUserDefaultTargets: [
      {
        value: null,
        targetName: 'userName',
        type: 'text',
        label: vm.$t('header.userMasterTable.userName'),
        text: null,
        rule: `required|max:${MaxLength.Default}`,
        key: 'userName',
      },
      {
        value: null,
        targetName: 'userMail',
        type: 'text',
        label: vm.$t('header.userMasterTable.userMail'),
        text: null,
        rule: `required|noSpace|pattern:mailAddress|max:${MaxLength.MailAddress}`,
        key: 'userMail',
      },
      {
        value: null,
        targetName: 'role',
        type: 'select',
        label: vm.$t('header.userMasterTable.role'),
        text: null,
        rule: 'selectRequired',
        key: 'role',
        listName: 'roleList',
        function: vm.onChangeRole,
      },
      {
        value: null,
        targetName: 'recruiterId',
        type: 'text',
        label: vm.$t('header.userMasterTable.recruiterId'),
        text: null,
        rule: `pattern:recruiterId|max:${MaxLength.RecruiterId}`,
        key: 'recruiterId',
      },
      {
        value: null,
        targetName: 'loginId',
        type: 'text',
        label: vm.$t('header.userMasterTable.loginId'),
        text: null,
        rule: `required|max:${MaxLength.Default}`,
        pattern: 'head',
        key: 'loginId',
      },
      {
        value: null,
        targetName: 'salesCode',
        type: 'text',
        label: vm.$t('header.userMasterTable.salesCode'),
        text: null,
        rule: `required|noSpace|pattern:salesCode`,
        pattern: 'sales',
        key: 'salesCode',
      },
      {
        value: null,
        targetName: 'agencyCode',
        type: 'text',
        label: vm.$t('header.userMasterTable.agencyCode'),
        text: null,
        rule: `required|noSpace|pattern:agencyCode`,
        pattern: 'agency',
        key: 'agencyCode',
      },
      {
        value: null,
        targetName: 'chargePersonName',
        type: 'text',
        label: vm.$t('header.userMasterTable.chargePersonName'),
        subheader: vm.$t('label.chargePersonInfo'),
        text: null,
        rule: `required|max:${MaxLength.Default}`,
        pattern: 'agency',
        key: 'chargePersonName',
      },
      {
        value: null,
        targetName: 'chargePersonPhoneNumber',
        type: 'phone',
        label: vm.$t('header.userMasterTable.chargePersonPhoneNumber'),
        text: null,
        rule: 'required|noSpace|telNumberLength',
        pattern: 'agency',
        key: 'chargePersonPhoneNumber',
      },
    ],

    // ユーザー登録データ
    createUserTargets: [],

    // ユーザー登録ダイアログが表示されているかどうか
    showCreateUserDialog: false,

    // ユーザー登録ボタンが有効であるかどうか
    isUserRegisterButtonEnabled: false,

    // ユーザー一括ボタンが有効であるかどうか
    isUserBulkButtonEnabled: false,

    // 検索条件クリアボタンが有効であるかどうか
    isClearSearchConditionButtonEnabled: false,

    // ローディング中であるかどうか
    isLoading: true,

    // 登録先選択ダイアログ表示制御
    showSelectOfficeTypeFormsDialog: false,

    // ユーザ投入先拠点
    selectOfficeTypeDefaultTargets: [
      {
        value: null,
        targetName: 'officeType',
        type: 'radio',
        label: null,
        text: null,
        rule: 'selectRequired',
        key: 'officeType',
        list: [
          {
            text: vm.$t('label.sales'),
            value: OfficeType.Sales,
          },
          {
            text: vm.$t('label.agency'),
            value: OfficeType.Agency,
          },
        ],
      },
    ],

    // 拠点種別
    officeType: 0,

    // 拠点コード一覧
    officeCodeList: [],

    // ユーザーID一覧
    loginIdList: [],

    csv: null,

    // 登録なのかどうか
    isRegister: null,

    // 出力なのかどうか
    isExport: null,

    // 営業店なのかどうか
    isSales: null,

    // 一括登録ユーザー一覧
    bulkRegisterUserList: {},

    // 一括更新ユーザー一覧
    bulkUpdateUserList: {},
  }),
  watch: {
    options: {
      handler() {
        this.fetchUsers();
      },
      deep: true,
    },
    userSearchCondition: {
      handler() {
        this.$set(
          this,
          'isClearSearchConditionButtonEnabled',
          Object.keys(this.userSearchCondition).length > 0
        );
      },
      deep: true,
    },
  },
  computed: {
    headers() {
      return UserListTableHeader.filter(header =>
        Role.canShow(this.userDetail().role, header.value)
      );
    },
  },
  async mounted() {
    // ページ遷移共通: ナビゲーション一覧更新
    this.$emit('updateNavigation');

    // ユーザー登録ボタンの有効状態をセットする
    this.$set(
      this,
      'isUserRegisterButtonEnabled',
      Role.isButtonEnabled(this.userDetail(), 'userRegister')
    );

    // ユーザー一括ボタンの有効状態をセットする
    this.$set(
      this,
      'isUserBulkButtonEnabled',
      Role.isButtonEnabled(this.userDetail(), 'userBulk')
    );

    this.setRoleChoices();
  },
  methods: {
    ...mapGetters('user', ['userDetail']),
    ...mapActions('ui', ['setLoading']),

    // メッセージを改行で表示
    splitMessageLine(message) {
      return splitMessageLine(message);
    },

    // 編集・参照ボタンが有効であるかどうか
    isDetailButtonEnabled(user) {
      return !user.isDeleted;
    },

    // ユーザー取得条件の取得
    getUserCondtion(userSearchCondition) {
      return {
        ...userSearchCondition,
      };
    },

    // 権限の選択肢の取得
    setRoleChoices() {
      // 操作可能な権限
      const roleList = Role.getChoices(this.userDetail().role);
      // 権限の選択肢表示の更新
      this.$set(this, 'roleList', roleList);
    },

    // オプションの取得
    getOption() {
      return {
        perPage: this.options.itemsPerPage,
        pageNumber: this.options.page,
        ...(this.options.sortBy.length > 0 && {
          sortKey: this.sortKey[this.options.sortBy[0]],
        }),
        ...(this.options.sortDesc.length > 0 && {
          isDesc: this.options.sortDesc[0],
        }),
      };
    },

    // ユーザー一覧の取得
    async fetchUsers() {
      // ユーザー一覧の取得
      const users = await getUsers(
        this.getOption(),
        this.getUserCondtion(this.userSearchCondition)
      ).catch(() => {
        // 空データをセットする
        this.$set(this, 'records', [{}]);
      });

      this.$set(this, 'isLoading', false);

      // ユーザー一覧が取得できなかった場合、以降の処理を中止する
      if (!users) return;

      // 表示値のマッピング
      const records = this.mapUsers(users);
      // ユーザー一覧表示の更新
      this.$set(this, 'records', records);
      this.$set(this, 'total', users.count);
    },

    // ユーザーデータのマッピング
    mapUsers(users) {
      return users.rows.map(user => ({
        detail: this.$t('button.view'),
        id: user.id,
        userName: user.userName,
        loginId: user.loginId,
        userMail: user.userMail,
        salesCode: this.mapSalesInfo(user, 'salesCode'),
        salesDepartmentName: this.mapSalesInfo(user, 'salesDepartmentName'),
        salesSectionName: this.mapSalesInfo(user, 'salesSectionName'),
        agencyCode: user.agency ? user.agency.agencyCode : null,
        agencyName: user.agency ? user.agency.agencyName : null,
        chargePersonName: user.chargePersonName,
        recruiterId: user.recruiterId,
        chargePersonPhoneNumber: user.chargePersonPhoneNumber,
        role: Role.getName(user.role),
        lastUpdatedDate: convertLastUpdatedDate(user.lastUpdatedDate),
        comment: user.comment,
        isDeleted: convertIsDeleted(user.isDeleted),
        lastLoginDate: convertLastLoginDate(user.lastLoginDate),
      }));
    },

    // 営業店データのマッピング
    mapSalesInfo(user, key) {
      return user.sale // 所属営業店が存在する場合
        ? user.sale[key] // 所属営業店データとする
        : user.agency && user.agency.sale // 所属代理店が存在するかつ、紐づく営業店が存在する場合
        ? user.agency.sale[key] // 所属代理店に紐づく営業店データとする
        : null;
    },

    // ユーザー登録キャンセル
    cancelCreateUser() {
      this.$set(this, 'showCreateUserDialog', false);
    },

    // ユーザー登録
    async createUser(item) {
      const user = {
        userName: item.userName,
        loginId: item.loginId,
        userMail: item.userMail,
        chargePersonName: Role.isAgency(item.role)
          ? item.chargePersonName
          : null,
        recruiterId: Role.isAgency(item.role) ? item.recruiterId : null,
        chargePersonPhoneNumber: Role.isAgency(item.role)
          ? item.chargePersonPhoneNumber
          : null,
        role: item.role,
        salesCode: item.salesCode,
        agencyCode: item.agencyCode,
      };
      // ユーザーの登録
      const result = await postUser(user).catch(error => {
        let message;
        switch (error.code) {
          case ErrorCode.UserLoginIdExists:
            message = this.$t('error.userLoginIdExists');
            break;
          case ErrorCode.NotAgency:
            message = this.$t('error.notAgency', {
              agencyCode: item.agencyCode,
            });
            break;
          case ErrorCode.NotSales:
            message = this.$t('error.notSales', { salesCode: item.salesCode });
            break;
          default:
            message = this.$t('error.userRegisterBadRequest');
        }
        this.$refs.errorDialog.open(
          this.$t('title.agency.userRegisterError'),
          message
        );
      });

      // ユーザーが登録できなかった場合、以降の処理を中止する
      if (!result) return;

      await this.fetchUsers();

      this.$set(this, 'showCreateUserDialog', false);

      // ユーザー詳細画面へ遷移する
      this.$router.push({
        path: `/user_detail/${result.id}`,
      });
    },

    // 権限の変更時
    async onChangeRole(item) {
      // ユーザー登録項目の設定
      const createUserTargets = JSON.parse(
        JSON.stringify(this.createUserDefaultTargets)
      )
        .filter(
          target =>
            !target.pattern || target.pattern === Role.getUserType(item.value)
        )
        .map(target => {
          // リストの取得
          if (target.listName) target.list = this[target.listName];
          // 入力値の取得
          const oldTarget = this.createUserTargets.find(
            t => t.targetName === target.targetName
          );
          if (oldTarget) target.value = oldTarget.value;

          return target;
        });

      // 関数の取得
      this.createUserDefaultTargets.forEach(target => {
        if (target.function) {
          createUserTargets.find(
            t => t.targetName === target.targetName
          ).function = target.function;
        }
      });

      this.$set(this, 'createUserTargets', createUserTargets);
    },

    // ユーザー登録ボタン押下時
    onClickUserRegisterButton() {
      // ユーザー登録項目の設定
      const createUserTargets = JSON.parse(
        JSON.stringify(this.createUserDefaultTargets)
      )
        .filter(target => !target.pattern)
        .map(target => {
          // リストの取得
          if (target.listName) target.list = this[target.listName];
          return target;
        });

      // 関数の取得
      this.createUserDefaultTargets.forEach(target => {
        if (target.function) {
          createUserTargets.find(
            t => t.targetName === target.targetName
          ).function = target.function;
        }
      });

      this.$set(this, 'createUserTargets', createUserTargets);
      this.$set(this, 'showCreateUserDialog', true);
    },

    // ユーザー検索ボタン押下時
    onClickUserSearchButton() {
      this.$refs.userSearchDialog.open();
    },

    // ユーザー検索成功時
    async onSuccessSearchUser(condition) {
      // ユーザー一覧の取得
      const users = await getUsers(
        this.getOption(),
        this.getUserCondtion(condition)
      ).catch(() => {
        this.$refs.userSearchDialog.onErrorSearch();
      });

      // ユーザー一覧が取得できなかった場合、以降の処理を中止する
      if (!users) return;

      // ユーザーが0件の場合、以降の処理を中止する
      if (users.count === 0) {
        this.$refs.userSearchDialog.onErrorSearch();
        return;
      }

      // ユーザー一覧検索条件の保存
      this.$set(this, 'userSearchCondition', condition);

      // ページングの初期化
      this.$set(this.options, 'page', userListDisplayDefault.page);

      // 表示値のマッピング
      const records = this.mapUsers(users);
      // ユーザー一覧表示の更新
      this.$set(this, 'records', records);
      this.$set(this, 'total', users.count);

      this.$refs.userSearchDialog.close();
    },

    // 検索条件クリアボタン押下時
    async onClickClearButton() {
      this.$refs.userSearchDialog.onClickClearButton();
      await this.fetchUsers();
    },

    // ユーザー検索条件のクリア
    onClearUserSearchCondition() {
      this.$set(this, 'userSearchCondition', {});
    },

    cancelSelectOfficeType() {
      this.$set(this, 'showSelectOfficeTypeFormsDialog', false);
    },

    // 一括登録ボタン押下時
    onClickUserBulkRegisterButton() {
      this.$set(this, 'isRegister', true);
      this.$set(this, 'isExport', false);
      this.$set(this, 'showSelectOfficeTypeFormsDialog', true);
    },

    // 一括更新ボタン押下時
    onClickUserBulkUpdateButton() {
      this.$set(this, 'isRegister', false);
      this.$set(this, 'isExport', false);
      this.$set(this, 'showSelectOfficeTypeFormsDialog', true);
    },

    // 一括削除ボタン押下時
    async onClickUserBulkDeleteButton() {
      this.$refs.userImportListDialog.open();
    },

    // CSVダウンロードボタンの押下時
    async onClickCsvDownloadButton() {
      this.$set(this, 'isExport', true);
      this.$set(this, 'showSelectOfficeTypeFormsDialog', true);
    },

    // 検索条件の変換
    convertSearchCondition(condition) {
      // 指定可能な権限
      const targetRoles = Role.getValues(this.officeType);
      // 指定権限
      let roles = [];
      // 権限指定が存在する場合
      if (condition.role) {
        // 指定可能な権限のみ抽出する
        roles = condition.role.filter(role => targetRoles.includes(role));
      }
      // 指定権限が存在しない場合
      if (!roles.length > 0) {
        // 指定可能な権限を指定権限とする
        roles = targetRoles;
      }
      return {
        ...condition,
        role: roles,
      };
    },

    // CSV エクスポート
    async onCsvExport() {
      // ユーザー一覧の取得
      const users = await getUsers(
        null,
        this.convertSearchCondition(
          this.getUserCondtion(this.userSearchCondition)
        )
      ).catch(err => {
        console.log(err);
      });

      if (!users) return;

      // 出力データ数が最大行数を上回った場合
      if (users.count > MaxCsvExportRowCount.Default) {
        this.$refs.errorDialog.open(
          this.$t('title.agency.csvExportError'),
          this.$t('error.invalidCsvMaxRowCount', {
            maxRowCount: MaxCsvExportRowCount.Default,
          })
        );

        return;
      }

      const importType = Master.getUserBulkImportType(
        this.isUpdate,
        this.officeType
      );
      const columns = UserBulkExportCsv.columns.filter(
        column =>
          column.importType === '*' || column.importType.includes(importType)
      );
      // CSV のヘッダー
      const headers = {};
      columns.forEach((column, index) => {
        // ヘッダー名をセットする
        headers[column.name] = column.text;
      });

      const rows = users.rows.map(user => ({
        loginId: user.loginId,
        userName: user.userName,
        userMail: user.userMail,
        ...(this.officeType === OfficeType.Agency && {
          salesCode: user.agency
            ? user.agency.sale
              ? user.agency.sale.salesCode
              : ''
            : '',
        }),
        ...(this.officeType === OfficeType.Sales && {
          salesCode: user.sale ? user.sale.salesCode : '',
        }),
        ...(this.officeType === OfficeType.Agency && {
          salesDepartmentName: user.agency
            ? user.agency.sale
              ? user.agency.sale.salesDepartmentName
              : ''
            : '',
        }),
        ...(this.officeType === OfficeType.Agency && {
          salesSectionName: user.agency
            ? user.agency.sale
              ? user.agency.sale.salesSectionName
              : ''
            : '',
        }),
        ...(this.officeType === OfficeType.Agency && {
          agencyCode: user.agency ? user.agency.agencyCode : '',
        }),
        recruiterId: user.recruiterId,
        ...(this.officeType === OfficeType.Agency && {
          chargePersonName: user.chargePersonName,
          chargePersonPhoneNumber: user.chargePersonPhoneNumber,
          admin: Role.getAgencyIsAdmin(user.role),
        }),
        lastLoginDate: user.lastLoginDate
          ? moment(user.lastLoginDate).format('YYYY/MM/DD HH:mm')
          : this.$t('label.notLogined'),
        comment: user.comment,
      }));

      // CSV をダウンロードする
      downloadCsv(rows, headers, getCsvFileName(this.$t('file.userExportCsv')));

      return;
    },

    // CSV インポート
    async onCsvImport(value) {
      this.$set(this, 'officeType', value.officeType);
      this.$set(this, 'showSelectOfficeTypeFormsDialog', false);

      if (this.isExport) {
        this.onCsvExport();
        return;
      }

      if (this.officeType === OfficeType.Sales)
        this.$set(
          this,
          'officeCodeList',
          await getSalesCodeList(this.officeType)
        );
      else
        this.$set(
          this,
          'officeCodeList',
          await getAgencyCodeList(this.officeType)
        );

      if (!this.isRegister) {
        const users = await getUsers(null, {
          role: Role.getValues(this.officeType),
        }).catch(err => {
          console.log(err);
        });
        if (!users) return;

        const loginIdList = users.rows.map(user => user.loginId);
        this.$set(this, 'loginIdList', loginIdList);
      }

      this.$refs.csvFileSelectDialog.open();
      // ファイル読み込みを初期化する
      this.$refs.csvFileSelectDialog.init();
    },
    // CSV読み込み成功
    async onSuccessCsvImport(csv, err) {
      if (err.length > 0) {
        this.$refs.userValidateErrorDialog.open(
          this.$t('title.agency.csvParseError'),
          this.$t('error.csvParseError'),
          err
        );
        return;
      }

      if (this.isRegister) {
        this.onSuccessBulkRegisterCsvImport(csv, err);
      } else {
        this.onSuccessBulkUpdateCsvImport(csv, err);
      }
    },

    // 一括登録用CSV読み込み成功
    async onSuccessBulkRegisterCsvImport(csv, err) {
      this.$set(
        this,
        'bulkRegisterUserList',
        this.convertUserBulkImportValue(csv)
      );

      const newUsers = this.bulkRegisterUserList.map(user => {
        return {
          ...user,
          role: Role.getName(user.role),
          startDate: moment(user.startDate).format('YYYY/MM/DD'),
        };
      });
      this.$refs.bulkRegisterListDialog.open(
        this.$t('title.agency.userBulkRegisterConfirm'),
        this.$t('description.userBulkRegisterConfirm'),
        Master.getUserBulkImportColumns(this.isRegister, this.officeType),
        newUsers,
        this.itemsPerPageOptions
      );

      this.$refs.csvFileSelectDialog.close();
    },

    // 一括更新用CSV読み込み成功
    async onSuccessBulkUpdateCsvImport(csv, err) {
      this.setLoading(true);

      this.$set(
        this,
        'bulkUpdateUserList',
        this.convertUserBulkImportValue(csv)
      );
      const loginIds = this.bulkUpdateUserList.map(user => user.loginId);
      const targetUsers = await getUsers(null, {
        role: Role.getValues(this.officeType),
      }).catch(err => {
        console.log(err);
      });
      if (!targetUsers) return;

      const newUsers = this.bulkUpdateUserList.map(user => {
        const targetUser = targetUsers.rows.find(
          targetUser => targetUser.loginId === user.loginId
        );
        const newUser = {
          loginId: this.convertChangeValue(user.loginId, user.loginId),
          userName: this.convertChangeValue(targetUser.userName, user.userName),
          userMail: this.convertChangeValue(targetUser.userMail, user.userMail),
          ...(this.officeType === OfficeType.Sales && {
            salesCode: this.convertChangeValue(
              targetUser.sale ? targetUser.sale.salesCode : '',
              user.salesCode
            ),
          }),
          ...(this.officeType === OfficeType.Agency && {
            agencyCode: this.convertChangeValue(
              targetUser.agency ? targetUser.agency.agencyCode : '',
              user.agencyCode
            ),
          }),
          recruiterId: this.convertChangeValue(
            targetUser.recruiterId,
            user.recruiterId
          ),
          ...(this.officeType === OfficeType.Agency && {
            chargePersonName: this.convertChangeValue(
              targetUser.chargePersonName,
              user.chargePersonName
            ),
            chargePersonPhoneNumber: this.convertChangeValue(
              targetUser.chargePersonPhoneNumber,
              user.chargePersonPhoneNumber
            ),
            role: this.convertChangeValue(
              Role.getName(targetUser.role),
              Role.getName(user.role)
            ),
          }),
          comment: this.convertChangeValue(targetUser.comment, user.comment),
        };

        return newUser;
      });

      this.$refs.bulkUpdateListDialog.open(
        this.$t('title.agency.userBulkUpdateConfirm'),
        this.$t('description.userBulkUpdateConfirm'),
        Master.getUserBulkImportColumns(this.isRegister, this.officeType),
        newUsers,
        this.itemsPerPageOptions
      );

      this.setLoading(false);
      this.$refs.csvFileSelectDialog.close();
    },

    // ユーザー一括インポート値の変換
    convertUserBulkImportValue(users) {
      return users.map(user => {
        // 代理店の場合、管理者フラグに応じて権限値をセットする
        if (this.officeType === OfficeType.Agency) {
          user.role = Role.getAgencyValue(user.admin);
          // 営業店の場合、固定の権限値をセットする
        } else if (this.officeType === OfficeType.Sales) {
          user.role = RoleType.SalesAdmin;
        }
        return user;
      });
    },

    // 変更値の変換
    convertChangeValue(oldValue, newValue) {
      oldValue = oldValue === null ? '' : oldValue;
      newValue = newValue === null ? '' : newValue;

      if (oldValue === newValue) {
        return {
          value: newValue,
          class: 'text-center v-data-table__divider',
        };
      } else {
        return {
          value: oldValue + '→' + newValue,
          class: 'text-center v-data-table__divider update',
        };
      }
    },

    // 一括登録
    async onBulkRegisterUser() {
      this.setLoading(true);

      const num = UserBulkRegisterCsv.maxNumberAtOneTime;
      let idx = 0;
      let hasError;
      let userImportId;
      while (idx < this.bulkRegisterUserList.length) {
        const result = await postUsers(
          this.bulkRegisterUserList.slice(idx, idx + num),
          this.officeType,
          userImportId
        ).catch(() => {
          this.$refs.bulkRegisterListDialog.onError();
          hasError = true;
        });
        if (!result) break;
        this.bulkRegisterUserList.splice(idx, idx + num),
          (userImportId = result.userImportId);
      }

      if (!hasError) {
        const errorList = await userDefinitiveRegistration(userImportId).catch(
          err => {
            console.log(err);
            return;
          }
        );

        if (errorList.length > 0) {
          const err = errorList.map(function(err) {
            return this.$t('label.honorificTitle', {
              name: err.userName,
            });
          }, this);

          this.$refs.userValidateErrorDialog.open(
            this.$t('title.agency.userBulkRegisterError'),
            this.$t('error.userBulkRegisterError'),
            err
          );
        } else {
          this.$refs.completedDialog.open(
            this.$t('title.agency.createResult'),
            this.$t('success.created')
          );
        }
        this.$refs.bulkRegisterListDialog.close();
      }
      this.setLoading(false);
    },
    // 一括更新
    async onBulkUpdateUser() {
      this.setLoading(true);

      const result = await putUsers(
        this.bulkUpdateUserList,
        this.officeType
      ).catch(() => {
        this.$refs.bulkUpdateListDialog.onError();
      });
      this.setLoading(false);
      if (!result) return;

      await this.fetchUsers();
      this.$refs.bulkUpdateListDialog.close();
      this.$refs.completedDialog.open(
        this.$t('title.agency.updateResult'),
        this.$t('success.updated')
      );
    },
  },
};
</script>
<style>
.v_data_table_fill_width {
  width: 100%;
}
thead.v-data-table-header {
  background-color: #ddebf7;
}
thead.v-data-table-header > tr > th {
  font-size: 14px !important;
  text-align: center !important;
  padding: 0;
  white-space: pre-wrap;
}
.v_footer_justify_evenly {
  justify-content: space-evenly;
}
.v-data-table-header__icon {
  /* テーブルのソートアイコンを常に表示 */
  opacity: 1 !important;
  color: rgba(0, 0, 0, 0.2) !important;
}
th.active > .v-data-table-header__icon {
  color: rgba(0, 0, 0, 1) !important;
}
.v-data-table td {
  word-break: break-all;
}
/* 1ページあたりの行数を IE 11 でも表示させる */
.v-data-footer__select .v-select {
  width: 54px !important;
}
.v-data-table {
  overflow: hidden;
}
</style>
