var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      ref: "dialog",
      attrs: { title: _vm.title, color: "next" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "back",
                  attrs: { width: "33%", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("button.cancel")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "next",
                  attrs: {
                    width: "33%",
                    dark: _vm.isDeleteButtonEnabled,
                    disabled: !_vm.isDeleteButtonEnabled
                  },
                  on: {
                    click: function($event) {
                      return _vm.onClickDeleteButton()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("button.delete")) + "\n    "
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("v-container", [
        _c("p", { staticClass: "text-center" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("description.deleteDialog.confirm")) +
              "\n    "
          )
        ])
      ]),
      _c("ErrorDialog", { ref: "errorDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }